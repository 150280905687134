<template>
  <a-modal :visible="visible" :mask-closable="false" :width="616" :title="$t('chuang-jian-zi-zhang-hao')" @cancel="handleClose">
    <div class="add-subaccount-modal">
      <a-form-model ref="newAccountFormRef" :model="newAccountForm" :rules="newAccountRules">
        <a-form-model-item :label="$t('xing-ming')" prop="userName">
          <a-input v-model="newAccountForm.userName" style="width: 420px;"/>
        </a-form-model-item>
        <a-form-model-item :label="$t('zi-zhang-hao')" prop="subAccount">
          <a-input v-model="newAccountForm.subAccount" :addon-after="`@${userInfo.userDomain}`"
                   style="width: 420px;"/>
        </a-form-model-item>
        <a-form-model-item :label="$t('deng-lu-mi-ma')" prop="password">
          <a-input v-model="newAccountForm.password" style="width: 330px;"/>
          <a-button style="margin-left: 10px;position: absolute;" @click="generateRandomPwd" type="primary" ghost>{{ $t('zi-dong-sheng-cheng') }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item :label="$t('jiao-se')" prop="roleId">
          <div style="display: flex;align-items: center;">
            <a-select v-model="newAccountForm.roleId" :placeholder="$t('qing-xuan-ze')" style="min-width: 330px;">
              <a-select-option v-for="role in roleList" :key="role.value" :value="role.value">
                {{ role.name }}
              </a-select-option>
            </a-select>
            <a-button type="link" @click="goRolePage">{{ $t('chuang-jian-jiao-se') }}</a-button>
          </div>
        </a-form-model-item>
        <h4>{{ $t('tong-zhi-jie-shou-she-zhi') }}</h4>
        <a-form-model-item :label="$t('shou-ji-hao')" prop="phone">
          <a-input v-model="newAccountForm.phone" style="width: 420px;"/>
        </a-form-model-item>
        <a-form-model-item :label="$t('you-xiang')" prop="email">
          <a-input v-model="newAccountForm.email" style="width: 420px;"/>
        </a-form-model-item>
        <div class="footer">
          <a-button type="primary" @click="handleAddSubAccount">{{ $t('chuang-jian') }}</a-button>
          <a-button @click="handleClose">{{ $t('qu-xiao') }}</a-button>
        </div>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import Vue from 'vue';
import { randomString } from '@/utils';

export default {
  name: 'AddSubaccountModal',
  props: {
    visible: Boolean,
    roleList: Array,
    handleClose: Function,
    getSubaccountList: Function
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  data() {
    const validateSubaccount = async (checkType, checkContent, callback, msg) => {
      const res = await this.$services.checkSubAccountDuplicate({
        data: {
          checkType,
          checkContent
        },
        modal: false
      });
      if (res.success) {
        callback();
      } else {
        callback(new Error(this.$t('msg-zhong-fu', [msg])));
      }
    };
    const validateSubaccountName = (rule, value, callback) => {
      validateSubaccount('SUB_ACCOUNT', value, callback, this.$t('zi-zhang-hao'));
    };
    const validateSubaccountPhone = (rule, value, callback) => {
      validateSubaccount('PHONE', value, callback, this.$t('shou-ji-hao'));
    };
    const validateSubaccountEmail = (rule, value, callback) => {
      validateSubaccount('EMAIL', value, callback, this.$t('you-xiang'));
    };
    return {
      newAccountForm: {
        userName: '',
        subAccount: '',
        password: '',
        roleId: undefined,
        phone: '',
        email: ''
      },
      newAccountRules: {
        userName: [{
          required: true,
          trigger: 'blur',
          message: this.$t('xing-ming-bu-neng-wei-kong')
        }],
        subAccount: [{
          required: true,
          trigger: 'blur',
          message: this.$t('zi-zhang-hao-bu-neng-wei-kong')
        }, {
          validator: validateSubaccountName,
          trigger: 'blur'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: this.$t('mi-ma-bu-neng-wei-kong')
        }, {
          min: 8,
          max: 32,
          message: this.$t('mi-ma-chang-du-bi-xu-wei-832'),
          trigger: 'blur'
        }],
        roleId: [{
          required: true,
          trigger: 'blur',
          message: this.$t('jiao-se-bu-neng-wei-kong')
        }],
        phone: [{
          required: true,
          trigger: 'blur',
          message: this.$t('shou-ji-hao-bu-neng-wei-kong')
        }, {
          validator: validateSubaccountPhone,
          trigger: 'blur'
        }],
        email: [{
          required: true,
          trigger: 'blur',
          message: this.$t('you-xiang-bu-neng-wei-kong')
        }, {
          validator: validateSubaccountEmail,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    generateRandomPwd() {
      let str = '';
      while (!str) {
        str = randomString(16);
      }
      Vue.set(this.newAccountForm, 'password', str);
      this.$refs.newAccountFormRef.validateField('password');
    },
    goRolePage() {
      this.$router.push({ name: 'System_Role' });
      window.$bus.emit('changeSidebar', '/ccsystem/role');
    },
    async handleAddSubAccount() {
      this.$refs.newAccountFormRef.validate(async (valid) => {
        if (valid) {
          const res = await this.$services.addSubAccount({
            data: {
              ...this.newAccountForm,
              subAccount: `${this.newAccountForm.subAccount}@${this.userInfo.userDomain}`
            }
          });

          if (res.success) {
            this.$Message.success(this.$t('chuang-jian-zi-zhang-hao-cheng-gong'));
            await this.getSubaccountList({});
            this.handleClose();
          }
        }
      });
    }
  }
};
</script>

<style lang="less">
.add-subaccount-modal {

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-label {
    width: 85px;
  }

}
</style>
